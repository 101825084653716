// Place for global overwrites of the Bootstrap.

// Table
@mixin border-radius-first-last {
  &:first-child {
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
  }
}

.table {
  color: #646c9a;
  margin-bottom: 0;

  thead th {
    font-weight: 500;
    border: 0;
    color: #32357c;
    background-color: #f1f3fc;

    @include border-radius-first-last;
  }

  td {
    vertical-align: middle;
    border: 0;
    font-weight: 500;
    color: #32357c;

    @include border-radius-first-last;
  }

  tr:nth-child(even) td {
    background-color: #f8f9fe;
  }
}

// Card

.card {
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: #ffffff;
  margin-bottom: 15px;
  border-radius: 15px;
  border: 1px solid #d1d6ea;

  @media screen and (min-width: 768px) {
    margin-bottom: 30px;
  }
}

.card-header {
  border-bottom: 1px solid #d1d6ea;
  padding: 15px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(68, 99, 255, 0.06);

  @media screen and (min-width: 768px) {
    min-height: 84px;
    padding: 30px;
  }

  &:first-child {
    border-radius: 15px 15px 0 0;
  }
}

.card-title {
  margin: 0;
  padding: 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #32357c;
}

.card-title > img:first-child {
  margin-right: 8px;
}

.card-body {
  padding: 20px 15px 15px;

  @media screen and (min-width: 768px) {
    padding: 20px 30px 30px;
  }
}

.card-footer {
  background-color: #f7f9ff;
  border-top: 1px solid rgba(50, 53, 124, 0.1);

  &:last-child {
    border-radius: 0 0 15px 15px;
  }
}

// Badge
.badge {
  font-weight: 500;
}

// Button
.btn {
  border-radius: 10px;
}

.btn-primary {
  box-shadow: 0 7px 13px rgba(68, 99, 255, 0.2);
  font-size: 16px;
  line-height: 25px;
  height: 40px;
  font-weight: 500;
  padding: 0 24px;
}

.btn-light {
  color: #646c9a;

  &:hover {
    color: darken(#646c9a, 30%);
  }
}

// Text utilities

.text-muted {
  color: rgba(50, 53, 124, 0.7) !important;
}

// Navbar

.nav-title {
  font-size: 26px;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.4em;
  letter-spacing: 0.04em;
  text-transform: none;
}

.nav-link {
  text-decoration: none;
  font-weight: 400;
  transition: color 0.3s;

  &:hover {
    color: #5867dd;
  }
}
.navbar-light .navbar-nav {
  .nav-link {
    color: #32357c;
  }

  .nav-link.active {
    font-weight: 500;
  }

  .nav-link.active,
  .nav-link:hover {
    color: #5867dd;
  }
}

.navbar-light .navbar-toggler {
  border: 0;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("/images/menu-icon.svg");
  width: 30px;
  height: 30px;
}

// Carousel

.carousel-image {
  padding: 50px;
}

.carousel-indicators li {
  background-color: darkgray;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  border-radius: 4px;
  background-color: lightgray;
}

// Input

.form-control {
  background-color: #f3f3f3;
  border: 0;
  border-radius: 10px;
  height: 42px;

  &:focus {
    background-color: #fff;
  }
}

// Vertical divider

.row.vertical-divider {
  overflow: hidden;
}
.row.vertical-divider > div[class^="col-"] {
  padding-top: 300px;
  text-align: center;
  border-left: 30px solid #c00;
  border-right: 3px solid #c00;
}
.row.vertical-divider div[class^="col-"]:first-child {
  border-left: none;
}
.row.vertical-divider div[class^="col-"]:last-child {
  border-right: none;
}

// Toggle 

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}
